<template>
  <div class="about">
    <h1 v-show="show">This is an about page</h1>
    <button @click="show = !show">Click Me</button>
    <div>
      <el-button @click="show = !show">Click Me</el-button>
      <div style="display: flex; margin-top: 20px; height: 100px;">
        <transition name="el-fade-in-linear">
          <div v-show="show" class="transition-box">.el-fade-in-linear</div>
        </transition>
        <transition name="el-fade-in">
          <div v-show="show" class="transition-box">.el-fade-in</div>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'About',
  data:() => ({
    show: true
  }),
  components: {
    
  }
}
</script>

<style scoped lang="scss">
  
</style>